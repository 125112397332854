(function () {
  'use strict';

  angular
    .module('login')
    .config(config);

  function config($stateProvider) {
   $stateProvider
     .state('login', {
       url: '/login',
       views: {
		alerts: {
			   templateUrl: 'alerts/alerts.tpl.html',
			   controller: 'AlertsCtrl',
			   controllerAs: 'alerts'
			},
         main: {
           templateUrl: 'login/login.tpl.html',
           controller: 'LoginCtrl',
           controllerAs: 'login'
         },
		 footer: {
           templateUrl: 'footer/footer.tpl.html',
           controller: 'FooterCtrl',
           controllerAs: 'footer'
         }
       }
     });
 }
  
}());
